<div class="container-table" [ngClass]="!enablePagination ? 'pb-5' : ''">
    <div class="tableFixHead">
        <table class="table_data">
            <thead [ngClass]="headerStyle">
                <tr>
                    @if(isActionsEnabled){
                        <th></th>
                    }
                    @for (header of tableHeaders; track $index) {
                        <th [ngClass]="{
                            'first-data': $index === 0,
                            'last-data': $index === tableHeaders.length - 1
                          }">
                            <div class="d-flex">
                                {{ (header.header| translated: header.header).toUpperCase() }}
                                <mat-icon class="cursor-pointer" (click)="onClickShortByData(header.dataName)">unfold_more</mat-icon>
                            </div>
                        </th>
                    }
                    @if(showSwitches){
                        <th class="text-end pe-5">{{switchHeader | uppercase}}</th>
                    }
                    
                </tr>
            </thead>
            <tbody [ngClass]="headerStyle">
                @for (item of (enablePagination ? filteredData : tableData); track $index) {
                    <tr (click)="onRowClic(item)" [ngClass]="{'selected-row': enableRowSelection && selectedRow === item}">
                        @if (isActionsEnabled) {
                            <td>  
                                <div class="d-flex">
                                    @if (editAction) {
                                        <app-button
                                            type="button"
                                            svg="mdi-pencil-outline-white"
                                            [scale]=".7"
                                            [injection]="{icon: 'margin-top: 0px !important', button: '' }"
                                            [applyStyles]="false"
                                            [applyChanges]="true"
                                        ></app-button> 
                                    }
                                    @if (deleteAction) {
                                        <app-button
                                            type="button"
                                            svg="mdi-delete-forever-outline-danger"
                                            [scale]=".7"
                                            [injection]="{icon: 'margin-top: 0px !important', button: '' }"
                                            [applyStyles]="false"
                                        ></app-button>
                                    }
                                </div>
                            </td>
                        }
                        @for (header of tableHeaders; track $index) {
                            <td [ngClass]="{
                                'first-data': $index === 0,
                                'last-data': $index === tableHeaders.length - 1
                              }">{{ item[header.dataName] }}</td>
                        }
                        @if (showSwitches) {
                            <td class="d-flex justify-content-end switch-td">
                                <app-so-switch 
                                    [customStyleOption]="'verde'" 
                                    [labelLeft]="switchLabelLeft"
                                    [labelRight]="switchLabelRight"
                                    [labels]="true"
                                    (statusChecked)="onSwitchActivated(item, $event)"
                                    (click)="$event.stopPropagation()"
                                    [isChecked]="item[switchIdentifier]"
                                    [disabled]="item.disabledSwitch"
                                    [matTooltip]="item.disabledSwitch ? 'El distribuidor debe estar activo' : ''"
                                ></app-so-switch>
                            </td>
                        }
                    </tr>
                }
                @empty {
                    <tr>
                        <td class="text-center pe-4 me-4" colspan="5" style="text-align: center;"> {{ 'no_data' | translated: 'Sin resultados'}} </td>
                    </tr>                                
                }
            </tbody>
        </table>
    </div>
    @if (enableSaveButton && !enablePagination) {
        <div class="d-flex first-data justify-content-end">
            <app-button
                class="pe-4"
                type="button"
                [text]="'save' | translated: 'Guardar'"
                svg="mdi-content-save"
                [iconAlignment]="'right'"
                [injection]="{icon: 'margin-top: 0px !important', button: '' }"
                [applyStyles]="false"
                [applyChanges]="true"
                [width]="'100%'"
                (clicked)="save()"
            ></app-button> 
        </div>
    }
    @if(enablePagination){
        <div class="d-flex first-data" [ngClass]="enableSaveButton ? 'justify-content-between' : 'justify-content-end'">
            <so-pagination
                [totalItems]="tableData.length"
                [itemsPerPage]="itemsPerPage"
                [currentPage]="currentPage"
                (pageChanged)="onPageChanged($event)"
            ></so-pagination>
            @if (enableSaveButton) {
                <app-button
                    class="pe-4"
                    type="button"
                    [text]="'save' | translated: 'Guardar'"
                    svg="mdi-content-save"
                    [iconAlignment]="'right'"
                    [injection]="{icon: 'margin-top: 0px !important', button: '' }"
                    [applyStyles]="false"
                    [applyChanges]="true"
                    [width]="'100%'"
                    (clicked)="save()"
                ></app-button> 
            }
        </div>
    }   
</div>