import { Injectable, signal } from '@angular/core';
import { TranslateService } from '@core/api/translate.service';
import { AlertType, DynamicComponentService } from '@shared/components/atomics/body-loading/dynamic-component-service.service';
import { openAlertFn } from '@shared/helpers/closures';
import { take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NoSaveDataAlertService {
  private openAlert = openAlertFn(this.dynamicComponentService, 'htmlContent');
  constructor(
    private dynamicComponentService: DynamicComponentService,
    private translateService :TranslateService,
  ){}

  private isSavingData = signal<boolean>(false);
  private activateAlert = signal<boolean>(true);

  public get $isSavingData():boolean { return this.isSavingData(); }
  public get $activateAlert():boolean { return this.activateAlert(); }

  public setIsSavingData(value: boolean){this.isSavingData.set(value); }
  public setActivateAlert(value: boolean){this.activateAlert.set(value); }

  public showNoSavedDataAlert(): Promise<boolean> {
    return new Promise((resolve) => {
      const alertDelete = this.openAlert(
        this.translateService.instant('warning', 'Advertencia'),
        '¿Estas seguro que deseas salir sin guardar los cambios?', //TODO: Translate
        AlertType.warning,
        false,
        true,
        true,
        () => { alertDelete.unsubscribe(); }
      ).pipe(take(1)).subscribe({
        next: (res) => {
          if (res) {
            resolve(true);
          } else {
            resolve(false);
          }
        }
      });
    });
  }

}
