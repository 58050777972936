<div class="popover-container">
    <div class="column-list">
        <ul class="items-list">
            @for( item of firstColumn; track $index){
                <li>
                    <span>
                        {{item}}
                    </span>
                </li>
            }
        </ul>
        <ul class="items-list">
            @for( item of secondColumn; track $index){
                <li>
                    <span>
                        {{item}}
                    </span>
                </li>
            }
        </ul>
    </div>
    <div class="d-flex justify-content-end">
        <app-button
            [svg]="'mdi-close-circle-outline'"
            [text]="'close' | translated: 'Cerrar'"
            [scale]=".7"
            [injection]="{ label: 'pt-2', icon:'position: relative !important; bottom: 5px;' }"
            [width]="'100%'"
            (click)="close()"
        ></app-button>
    </div>
</div>