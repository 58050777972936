<div class="container-navigation">
 @if(showText) {
  <div class="text-center so-text-3-bold so-neuter" style="position: relative;">
   @if (!loadingTotal || textShowRecordsPart1 === null) {
    {{ textShowRecords }}
   }
   @else {
    <span class="me-1">{{ textShowRecordsPart1}}</span>
    <span class="spinner"></span>
    <span class="ms-4" [ngClass]="{ 'me-1': textShowRecordsPart3 !== null }">{{ textShowRecordsPart2 }}</span>
    @if (textShowRecordsPart3 !== null) {
    <span class="spinner"></span>
    <span class="ms-4">{{ textShowRecordsPart3 }}</span>
    }
   }
  </div>
 }
  <div class="d-flex justify-content-end content-pagination">
    <ul class="pagination" [style]="'padding-right: ' + paddingEnd + ';'">
      <!-- Primera página -->
      <button
        class="btn mx-1 pagination-button d-flex justify-content-center align-items-center transparent no-padding"
        (click)="goToPage(1)" [disabled]="currentPage === 1" [ngClass]="{ 'disab': prevDisabled }" [disabled]="prevDisabled">
        <mat-icon class="so-secondary" style="transform: scale(2);rotate: -180deg;">skip_next</mat-icon>
      </button>
      <!-- Página anterior -->
      <button
        class="btn mx-1 pagination-button d-flex justify-content-center align-items-center transparent no-padding"
        (click)="goToPreviousPage()" [disabled]="currentPage === 1" [ngClass]="{ 'disab': prevDisabled }" [disabled]="prevDisabled">
        <mat-icon class="so-secondary" style="transform: scale(2);">arrow_left</mat-icon>
      </button>
      <!-- Página n-1 -->
      <ng-container *ngIf="currentPage > 1">
        <button class="btn mx-1 pagination-button" (click)="goToPreviousPage()" [ngClass]="{ 'disab': prevDisabled }" [disabled]="prevDisabled">
          {{ currentPage - 1 }}
        </button>
      </ng-container>
      <!-- Página actual -->
      <button class="btn mx-1 pagination-button active">
        {{ currentPage }}
      </button>
      <!-- Página n+1 -->
      <ng-container *ngIf="currentPage < getTotalPages()">
        <button class="btn mx-1 pagination-button" (click)="goToNextPage()" [ngClass]="{ 'disab': nextDisabled }" [disabled]="nextDisabled">
          {{ currentPage + 1 }}
        </button>
      </ng-container>
      <!-- Siguiente página -->
      <button
        class="btn mx-1 pagination-button d-flex justify-content-center align-items-center transparent no-padding"
        (click)="goToNextPage()" [disabled]="currentPage === getTotalPages()" [ngClass]="{ 'disab': nextDisabled }" [disabled]="nextDisabled">
        <mat-icon class="so-secondary arrow" style="transform: scale(2);">arrow_right</mat-icon>
      </button>
      <!-- Última página -->
      <button
        class="btn mx-1 pagination-button d-flex justify-content-center align-items-center transparent no-padding"
        (click)="goToPage(getTotalPages())" [disabled]="currentPage === getTotalPages()" [ngClass]="{ 'disab': nextDisabled }" [disabled]="nextDisabled">
        <mat-icon class="so-secondary arrow" style="transform: scale(2);">skip_next</mat-icon>
      </button>
    </ul>
  </div>
</div>
