import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-so-radio',
  templateUrl: './so-radio.component.html',
  styleUrls: ['./so-radio.component.scss'],
})
export class  SoRadioComponent implements OnInit, OnChanges {
  @Input() checked: boolean | number = false;
  @Input() checkLabel: boolean = true;
  @Input() ratioId?: string;
  @Input() nameGroup?: string;
  @Input() text?: string;
  @Input() opacity?: string = '';
  @Input() gap?: string = '1.2rem';
  @Input() labelPosition?: string;
  @Input() fontWeight: string = '500';
  @Input() labelColor: string = '#272D3B';
  @Input() fontSize: string ='14px';
  @Input() isDisabled: boolean = false;
  @Input() canDisableItself: boolean = true;
  @Input() labelContainerStyle: string = '';
  @Input() labelStyle: string = '';
  @Input() checkedIconStyle: string = '';
  @Output() onClick: EventEmitter<void> = new EventEmitter();
  autoGap: boolean = true;

  ngOnInit(): void {
    this.positionLabel();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const keys = Object.keys(changes);
    if (keys.includes('gap'))
      this.autoGap = false;
    if (this.autoGap && keys.includes('checked'))
      this.gap = this.checked ? '.92rem' : '1.2rem';
  }

  validarStatus($event: any) {
    if(!this.isDisabled && (!this.checked || this.canDisableItself)){
      let check = $event.target.checked;
      this.checked = check;
    }
  }
  positionLabel() {
    if (this.labelPosition === 'right') {
      this.labelPosition = 'flex-row-reverse';
    }
  }
}
