import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'so-input-search',
  templateUrl: './so-input-search.component.html',
  styleUrl: './so-input-search.component.scss'
})
export class SoInputSearchComponent {
  @ViewChild('input') input!: ElementRef<HTMLInputElement>;
  @Output() valueChange = new EventEmitter<string>();
  @Input() placeholder: string = '';
  @Input() label: string = '';
  @Input() maxlength: number = 50;
  @Input() minlength: number = 0;
  @Input() customStyle: {} = {};
  @Input() textColor: string = '';
  @Input() disabled: boolean = false;
  @Input() updatedStyles: boolean = false;

  public inputValue: string = ''; // Track the input value

  onChangeValue(value: string): void{
    this.valueChange.emit(value);
  }

  getValue(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }

  clearInput(): void {
    this.resetInput();
  }


  resetInput(): void {
    this.inputValue = '';
    this.valueChange.emit(this.inputValue);
    this.input.nativeElement.value = '';
    this.input.nativeElement.focus();
  }

}
