import { CommonModule } from '@angular/common';
import { booleanAttribute, Component, Input } from '@angular/core';

@Component({
  selector: 'app-so-required-asterisk',
  standalone: true,
  imports: [ CommonModule ],
  templateUrl: './so-required-asterisk.component.html',
})
export class RequiredAsteriskComponent {
  @Input({ transform: booleanAttribute }) show: boolean = true;
  @Input({ required: true, transform: booleanAttribute }) valid!: boolean;
  @Input({ transform: booleanAttribute }) space: boolean = false;
  @Input() fontSize: string = '14px';
  @Input() validColor: string = 'var(--so-success)';
  @Input() invalidColor: string = 'var(--so-danger)';
}
