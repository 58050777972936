import { Component, ElementRef, EventEmitter, inject, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@core/api/translate.service';
import { AlertType, DynamicComponentService } from '@shared/components/atomics/body-loading/dynamic-component-service.service';

@Component({
  selector: 'app-so-file-drop-zone',
  templateUrl: './so-file-drop-zone.component.html',
  styleUrl: './so-file-drop-zone.component.scss'
})
export class SoFileDropZoneComponent {
  @ViewChild('fileInput', { static: false }) fileInput!: ElementRef<HTMLInputElement>;

  @Output() saveFilesEvent = new EventEmitter<File[]>();
  @Input() excludeMimeTypes: string[] = [];
  @Input() validateByModal = false;
  @Input() validateMarca = false;
  @Input() disabled: boolean = false;
  @Input() showMessage: boolean = false;
  @Input() showButtonSave: boolean = true;
  @Input({required: true}) idInput: string = '';

  public files: File[] = [];
  public isDragging = false;

  private readonly dynamicComponentService = inject(DynamicComponentService);
  private readonly translateService = inject(TranslateService);

  public onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragging = true;
  }

  public onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragging = false;
  }

  public onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragging = false;

    if (event.dataTransfer?.files) {
      this.handleFiles(event.dataTransfer.files);
    }
  }

  public onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files) {

      const processedFiles = Array.from(input.files).map(file => {
        const cleanedFileName = file.name
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/[^a-zA-Z0-9.]/g, '');

          return new File([file], cleanedFileName, { type: file.type });
      });

      this.handleFiles(processedFiles);
      this.saveFilesEvent.emit(processedFiles);
      this.clearFiles();
    }  
  }

  /* handleFiles(files: FileList) {
    Array.from(files).forEach(file => {
      if (!this.isFileDuplicate(file)) {
        this.files.push(file);
      }
    });
  } */

  private handleFiles(files: FileList | File[]) {
    Array.from(files).forEach(file => {
      if (!this.isFileDuplicate(file) && !this.isExcludedFileType(file)) {
        this.files.push(file);
      } else if (this.isExcludedFileType(file)) {
        this.dynamicComponentService.showAlert({
          title: `El tipo de archivo ${file.type} no está permitido.`,
          alertType: AlertType.warning,
          autohide: true,
          animate: true,
          showButtons: false,
          showButtonClose: true
        });
      }
    });
  }

  private isExcludedFileType(file: File): boolean {
    return this.excludeMimeTypes.some(excludedType => file.type.startsWith(excludedType));
  }

  private isFileDuplicate(newFile: File): boolean {
    return this.files.some(existingFile =>
      existingFile.name === newFile.name && existingFile.size === newFile.size
    );
  }

  public removeFile(index: number) {
    this.files.splice(index, 1);
  }

  public saveFiles() {
    this.saveFilesEvent.emit(this.files);
    this.clearFiles();
  }

  public validateModal() {
    this.saveFilesEvent.emit(this.files);
    this.clearFiles();
  }

  private clearFiles(): void {
    this.files = [];
  }

  public resetFileInput() {
    this.fileInput.nativeElement.value = ''; // Limpia el input
    this.files = []; // Limpia la lista de archivos
  }


  public getIconForFile(file: File): string {
    if (!file || !file.name) return 'default-icon'; // Ícono por defecto si no hay archivo
  
    const extension = file.name.split('.').pop()?.toLowerCase(); // Obtener extensión del archivo
  
    // Mapeo de extensiones a iconos
    switch (extension) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return 'image'; // Ícono para imágenes
      case 'mp4':
      case 'avi':
      case 'mov':
        return 'ondeman_video'; // Ícono para videos
      case 'doc':
      case 'docx':
        return 'description'; 
      case 'xls':
      case 'xlsx':
      case 'csv':
        return 'border_all';
      case 'pdf':
        return 'picture_as_pdf'; // Ícono para PDFs
      default:
        return 'insert_drive_file'; // Ícono genérico para otros tipos
    }
  }

  public getColorForFile(file: File): string {
    if (!file || !file.name) return 'default-icon'; // Ícono por defecto si no hay archivo
  
    const extension = file.name.split('.').pop()?.toLowerCase(); // Obtener extensión del archivo
  
    // Mapeo de extensiones a iconos
    switch (extension) {
      case 'pdf':
        return 'var(--so-danger)'; // Ícono para PDFs
      case 'xls':
      case 'xlsx':
      case 'csv':
       return 'var(--so-success-thick)';
      default:
        return 'var(--color-icon)'; // Ícono genérico para otros tipos
    }
  }

}
