import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'so-checkbox-reactive-form',
  templateUrl: './so-checkbox-reactive-form.component.html',
  styleUrl: './so-checkbox-reactive-form.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SoCheckboxReactiveFormComponent),
      multi: true
    }
  ]
})
export class SoCheckboxReactiveFormComponent implements ControlValueAccessor {
 
  @Output() changeCheckbox = new EventEmitter<boolean>();
  @Input() name?: string = '';
  @Input() customClass: string = '';
  @Input() customStyle: string = '';
  @Input() checkMarkStyle: string = '';
  @Input() isChecked: boolean = false;
  @Input() noAnimation: boolean = false;
  @Input() isDisabled: boolean = false;
  onChange: any = () => {};
  onTouched: any = () => {};

  constructor() { }

  writeValue(value: any): void {
    this.isChecked = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  toggleCheckbox(event: any): void {
    if (!this.isDisabled) {
      const checked = (event.target as HTMLInputElement).checked;
      this.isChecked = checked;
      this.onChange(checked);
      this.onTouched();
      this.changeCheckbox.emit(checked);
    }
  }

}
