<div class="select__form" [ngClass]="{'p-1': !isWhiteBox, 'd-flex': display === 'horizontal'}">
    @if( selectLabel ) {
        <label class="fw-medium p-1" [style]="labelStyle" [ngClass]="display === 'horizontal' ? 'so-primary so-text-3-bold pe-3' : 'custom-reactive-select-label'"> 
            {{ selectLabel }} <app-so-required-mark [show]="requiredMark" [isValid]="touched && valid" />
        </label>
    }
    <div class="select-container" [ngClass]="{'white-box': isWhiteBox}">
        <select #selectElement (change)="updateValue($event)" [value]="value" [disabled]="isDisabled" [name]="name"
            class="custom-reactive-select custom-reactive-select-arrow form-select form-select-sm app-so-input-border w-100 text-truncate"
            [class.custom-reactive-select-arrow]="!isLoading"
            [style]="customStyle"
            [matTooltip]="(tooltipDisabledText && isDisabled) ? tooltipDisabledText : ''"
            matTooltipPosition="above">
          @if (!isWhiteBox) {
            <option value="" hidden>{{ 'selected' | translated : 'Seleccionar' : lang }} </option>
          }
          @for (item of items; track $index) {
            <option [value]="item.key" [disabled]="shouldBeDisabled(item.key)" [selected]="item.key == selectedItemId">{{ item.value | translate }}</option>
          }
        </select>
        @if (currentDisplayItem !== null) {
          <span [class]="('display-value text-truncate ' + displayValueClass).trim()" [style]="displayValueStyle">{{ currentDisplayItem }}</span>
        }
        @if(isLoading){
            <div class="spinner"></div>
        }
    </div>
</div>

