import { AfterViewInit, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'so-range-slider',
  templateUrl: './so-range-slider.component.html',
  styleUrl: './so-range-slider.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SoRangeSliderComponent),
      multi: true,
    },
  ],
})
export class SoRangeSliderComponent implements ControlValueAccessor{
  @Input() label: string = '';
  @Input() labelValue: string = '';
  @Input() minValue: number = 0.0;
  @Input() maxValue: number = 100;
  @Input() convertNumberLabel: string = '';
  @Input() converNumberValue: number = 1;
  @Input() isInteger: boolean = true;
  @Input() tooltip: string = '';
  @Input() showIcon: boolean = false;
  value: number = 0; // Valor del rango
  isDisabled: boolean = false;

  // Métodos del ControlValueAccessor
  onChange: (value: number) => void = () => {};
  onTouched: () => void = () => {};

  // Método para actualizar el valor
  updateValue(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).valueAsNumber;
    this.value = inputValue / this.converNumberValue;
    this.onChange(inputValue);
  }

  // Implementación de ControlValueAccessor
  writeValue(value: number): void {
    this.value = value || 0;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

}
