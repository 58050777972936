import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonStyleInjection } from '@shared/dto/button.style-injection.dto';
import { SoIconComponent } from '../so-icon/so-icon.component';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RequiredAsteriskComponent } from "@shared/components/atomics/so-required-asterisk/so-required-asterisk.component";

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [CommonModule, SoIconComponent, MatTooltipModule, RequiredAsteriskComponent],
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Output() clicked = new EventEmitter<any>();

  @Input() icon: string = '';
  @Input() colorIcon: string = '';
  @Input() textStyle: string = '';
  @Input() buttonStyle: string = '';
  @Input() textStyleIsActive: boolean = false;
  @Input() isDangerStyle: boolean = false;
  @Input() svg: string = '';
  @Input() text: string = '';
  @Input() scale: number = 1;
  @Input() disabled: boolean = false;
  @Input() circle: boolean = false;
  @Input() iconRotation: string = '0deg';
  @Input() extra: string | number | null = null;
  @Input() extraTooltip: string = '';
  @Input() showRequiredAsterisk: boolean = false;
  @Input() isValid: boolean = false;
  @Input() validColor: string = 'var(--so-success)';
  @Input() inValidColor: string = 'var(--so-danger)';
  @Input() asteriskFontSize: string = '14px';
  @Input() asteriskSpace: boolean = false;
  @Input() height: string = '100%';

  @Input() color: 'success' | 'primary' | 'warning' | 'danger' | 'light' = 'light';
  @Input() type: 'button' | 'submit' | 'reset' = 'button';
  @Input() iconAlignment: 'left' | 'right' = 'left';

  @Input() injection: ButtonStyleInjection = {};
  @Input() width: string = '150%';
  @Input() tooltip: string = '';
  @Input() customStyleTooltip: string = '';
  @Input() applyStyles: boolean = true;
  @Input() applyChanges: boolean = false;
  @Input() containerStyle: string = '';

  public styleButton: string = '';
  public styleText: string = '';
  public styleIcon: string = '';
  public showTooltip: boolean = false;

  ngOnInit(): void {
    let currentColor: string = 'inherit';
    let currentText: string = 'white';
    let currentIcon: string = 'white';

    switch (this.color) {
      case 'success':
        currentColor = 'so-green';
        break;
      case 'primary':
        currentColor = 'so-blue';
        break;
      case 'warning':
        currentColor = 'so-yellow';
        break;
      case 'danger':
        currentColor = 'so-red';
        break;
      default:
        currentColor = 'inherit';
        currentText = 'so-black';
        currentIcon = 'so-green';
    }

    if(this.applyStyles){
      this.styleButton = `bg-${currentColor} ${this.circle ? 'rounded-full px-2 py-2' : 'px-7 py-2'}`;
    }
    this.styleText = `text-${currentText}`;
    this.styleIcon = `text-${currentIcon}`;
  }

  onClick($event: MouseEvent): void {
    this.clicked.emit($event);
  }
}

