<div class="container-lateral-menu" [style]="customStyle">
  @if (showLogo) {
    <section class="logotipo">
      <img src="assets\img\op.png" width="54" />
      <div style="height: 6px;"></div>
      @if(showVersionApp){
        <p> V.10 </p>
      }
    </section>
  }
    @if(showTextApp){
      <div class="vertical-text visible" [style]="'color: rgba(0, 42, 127, ' + textAppAlpha + '); top: ' + textAppTop">
       @if (highlight === null) {
        <span>{{ textApp | uppercase }}</span>
       }
       @else {
        <span>{{ textAppLeft | uppercase }}</span><span [style]="'color: ' + highlight.color + ';'">{{ textAppMiddle | uppercase }}</span><span>{{ textAppRight | uppercase }}</span>
       }
      </div> 
    }
</div>
