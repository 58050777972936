import { Component, Input, ViewChild, ViewContainerRef, Output, EventEmitter, effect, SimpleChanges, Injector, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from '@modules/configuration-ia/services/api/token.service';
import { NoSaveDataAlertService } from '@core/services/no-save-data-alert/no-save-data-alert.service';
import { SharedStateKbdService } from '@modules/configuration-ia/services/signals/kbd/shared-state-kbd.service';
import { Tab } from '@shared/interfaces/nav-bar-ia.interfaces';

@Component({
  selector: 'app-so-tabs',
  templateUrl: './so-tabs.component.html',
  styleUrls: ['./so-tabs.component.scss']
})
export class SoTabsComponent{
  private injector = inject(Injector);
  @Input() tabs: Tab[] = [];
  @Input() tabsDisplay: 'horizontal' | 'vertical' | 'sections' | 'steps' = 'horizontal';
  @Input() activeTabIndex: number = 0;
  @Input() type: 'simple' | 'principal' = 'simple';
  @Input() tabContentClass: string = '';
  @Input() tooltipDisabledText: string = '';
  @Input() contentCard: boolean = true;
  @Input() enableUnsavedDataCheck: boolean = false;
  @Output() activeTabIndexChange = new EventEmitter<number>();

  public isUploading: boolean = true;
  public isUploadingTextFile: boolean = true;
  public isSavingData: boolean = this.noSaveDataAlertService.$isSavingData;
  private savedTabIndex: number | null = null;
  @ViewChild('tabContainer', { read: ViewContainerRef, static: false }) tabContainer!: ViewContainerRef;
  constructor(
    public route: Router,
    public tokenService: TokenService,
    private sharedStateKdbService: SharedStateKbdService,
    private noSaveDataAlertService: NoSaveDataAlertService,
  ){
    this.getStateUploading();
  }
  ngAfterViewInit(): void {
    //Se pone en el init ya que al no estar en el contexto del constructor por ser un @input no se valida desde ahí
    if(this.enableUnsavedDataCheck){
      this.activateIsSavingEffect();
    }
  }
  activateIsSavingEffect(){
    effect(() => {
      this.isSavingData = this.noSaveDataAlertService.$isSavingData; 
      if(this.savedTabIndex != null && !this.isSavingData && this.noSaveDataAlertService.$activateAlert){
        this.activeTabIndex = this.savedTabIndex;
      }
    }, { injector: this.injector });
  }

  setActiveTab(index: number): void {
    if(this.noSaveDataAlertService.$isSavingData && this.enableUnsavedDataCheck){
      this.savedTabIndex = index;
      return;
    }
    this.activeTabIndex = index;
    this.activeTabIndexChange.emit(this.activeTabIndex);
  }

  private getStateUploading(){
    this.sharedStateKdbService.isCompleted$.subscribe({
      next: (value) => {
        this.isUploading = value;
      }
    });
    this.sharedStateKdbService.textFileIsCompleted$.subscribe({
      next: (value) => {
        this.isUploadingTextFile = value;
      }
    });
  }

}