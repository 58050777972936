import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'so-popover',
  templateUrl: './so-popover.component.html',
  styleUrl: './so-popover.component.scss'
})
export class SoPopoverComponent {
  @Input() items: any[] = []; 
  @Output() closePopover = new EventEmitter<void>();  

  firstColumn: any[] = [];
  secondColumn: any[] = [];

  ngOnInit(): void {
    if (this.items.length > 50) {
      const middleIndex = Math.ceil(this.items.length / 2);
      this.firstColumn = this.items.slice(0, middleIndex);
      this.secondColumn = this.items.slice(middleIndex);
    } else {
      this.firstColumn = this.items;
      this.secondColumn = [];
    }
  }

  close() {
    this.closePopover.emit();
  }

}
