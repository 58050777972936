import { Component, Input, forwardRef, booleanAttribute, Output, EventEmitter, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CursorPosition } from '@shared/interfaces/CursorPosition.interface';

@Component({
  selector: 'app-so-text-area',
  templateUrl: './so-text-area.component.html',
  styleUrls: ['./so-text-area.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SoTextAreaComponent),
      multi: true
    }
  ]
})
export class SoTextAreaComponent {
  @Input() text: string = '';
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() rows: string = '2';
  @Input() disabled: boolean = false;
  @Input() styles: string = '';
  @Input() name: string = '';
  @Input() areaStyles: string = '';
  @Input() paddingClasses: string = 'p-1';
  @Input() inputId?: string;
  @Input() applySanitize: boolean = false;
  @Input() maxlength: number | null = 2000;
  @Input() labelStyle: string = '';
  @Input() formGroup?: any;
  @Input({ transform: booleanAttribute }) requiredMark: boolean = false;
  @Output() valueChanged = new EventEmitter<string>();
  @ViewChild('textarea') textarea!: ElementRef<HTMLTextAreaElement>;
  @Output() positionChanged = new EventEmitter<CursorPosition>();
  @Input() insertText: CursorPosition = {
    start: 0,
    end: 0
  };
  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(value: any): void {
    if (value !== undefined) {
      this.text = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  getValue(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }

  updateValue(value: string): void {
    this.text = value;
    this.onChange(value);
    this.onTouch();
    this.valueChanged.emit(value);
  }
  onCursorPositionChange(): void {
    if (this.textarea) {
      const textarea = this.textarea.nativeElement;
      this.positionChanged.emit({
        start: textarea.selectionStart,
        end: textarea.selectionEnd
      });
    }
  }
}
