<div class="p-1">
    <div [class]="directionLabel(position)">
        @if( label !== ''){
            <label class="fw-medium p-1" [style.color]="textColor">{{ label }}<app-so-required-mark [show]="requiredMark" [isValid]="touched && valid" /></label>
        }
        <div class="d-flex align-items-center">
            <div class="position-relative d-inline-flex w-100 content__input-date">
                <input 
                    class="app-so-input-border w-100 input-date"
                    style="width: 30px; padding: 3px; border-radius: 10px;"
                    data-field="date"
                    type="date"
                    (input)="onInputChange($event)"
                    [value]="value"
                    [disabled]="isDisabled"
                    [min]="minDate"
                    [max]="maxDate"
                />
                <p [ngClass]="isDisabled ? 'disabled-container-date' : ''" [style]="valueStyle">{{ value | date: 'dd-MM-yyyy' }}</p>
            </div>
        </div>
    </div>
</div>