import type { TranslateService } from "@core/api/translate.service";

// Binding del servicio de traducción a una función en la que el parámetro "fallback" es requerido
// y el resultado es explícitamente de tipo string
export const translateFn = (translateService: TranslateService) =>
  (label: string, fallback: string, lang: string = '') => String(translateService.instant(label, fallback, lang));

// Misma función; con parámetro "lang" requerido
export const translateLangRequiredFn = (translateService: TranslateService) =>
  (label: string, fallback: string, lang: string) => String(translateService.instant(label, fallback, lang));
