import { Component, inject, Input, numberAttribute, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@core/api/translate.service';

export type simplebarHightlightedText = {
  text: string,
  color: string,
} | null;

@Component({
  selector: 'so-sidebar-simple',
  templateUrl: './so-sidebar-simple.component.html',
  styleUrl: './so-sidebar-simple.component.scss'
})
export class SoSidebarSimpleComponent implements OnChanges {
  // Servicios
  private readonly translateService = inject(TranslateService);

  // Inputs
  @Input() showVersionApp: boolean = true;
  @Input() customStyle: string = '';
  @Input() showTextApp: boolean = false; 
  @Input() showLogo: boolean = true;
  @Input() textApp: string = this.translateService.instant('ai_configuration', 'Configuración IA');
  @Input() textAppTop: string = '96%';
  @Input() highlight: simplebarHightlightedText = null;
  @Input({ transform: numberAttribute }) textAppAlpha: number = 0.2;

  // Atributos
  textAppLeft: string = '';
  textAppMiddle: string = '';
  textAppRight: string = '';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['highlight']) {

      if (this.highlight === null) {
        this.textAppLeft = '';
        this.textAppMiddle = '';
        this.textAppRight = '';
        return;
      }
      
      const regEx = new RegExp(this.highlight.text, 'i');
      const separatorMatch: string[] | null = this.textApp.match(regEx);
      if (!separatorMatch || separatorMatch.length === 0) return;

      const textAppSplitted = this.textApp.split(regEx, 2);
      if (textAppSplitted.length !== 2) return;

      this.textAppLeft = textAppSplitted[0];
      this.textAppMiddle = separatorMatch[0];
      this.textAppRight = textAppSplitted[1];
    }
  }
}
