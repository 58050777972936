import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SortingHelperService {

  sortArray(array: any[], field: string, direction: 'asc' | 'desc' = 'asc'): any[] {
    return array.sort((a, b) => {
      const valueA = this.resolveField(a, field);
      const valueB = this.resolveField(b, field);

      if (valueA < valueB) {
        return direction === 'asc' ? -1 : 1;
      } else if (valueA > valueB) {
        return direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }

  private resolveField(object: any, field: string): any {
    return field.split('.').reduce((o, key) => (o ? o[key] : null), object);
  }
}
