<!-- Notifications  -->
@switch (sizeCard) {
  @case ('notifications'){
    <div class="card  card-border " [class]="'br-'+ brColor">
      <div class="card-body card-body-padding-notificacions ">
        <div class="row" style="    min-height: 80px;">
          <div class="d-flex align-items-center col-12 col-sm-12 col-lg-12 col-xl-9">
            @if (icon) {
              <app-so-badge icon="{{icon}}" [isCircle]="true" color="{{brColor}}" fontSize="18px" style="margin-right: 1rem;"></app-so-badge>
            }
            <div>
              <p class="card-title-medium text-start" [ngClass]="{'text-nowrap overflow-hidden': !allowTextWrap}">{{title}}</p>
              <p class="card-description text-start" [class]=" 'description-color-'+ brColor">{{description}}</p>
            </div>
          </div>
          <div class=" col-12 col-sm-12 col-lg-12 col-xl-3">
            <h6 class="card-datee text-end">{{date}}</h6>
          </div>
         
        </div>
       
       
        <div class="d-flex justify-content-between mt-2">
          @if (badgeText) {
            <app-so-badge text="{{badgeText | translate}} " color="{{brColor}}" fontSize="12px" fontWeight="bold"></app-so-badge>
          }
        
          <div class="d-flex ">
            @for (icons of icons; track $index) {
              <mat-icon class=" icon-check-circle mr-3" style="font-size: 24px;">{{icons}}</mat-icon>
            }
            
          </div>
        </div>
        <div>
        
        </div>
        <div>
          <ng-content select=".notifications">
          
          </ng-content>
        </div>
      </div>
    </div>
  }
  @case ('extraLarge'){
    <!-- EXTRA LARGE -->
    <div class="card  card-border " [class]="'br-'+ brColor">
      <div class="card-body card-body-padding ">
        <div class="d-flex align-items-center">
          @if (icon) {
            <app-so-badge icon="{{icon}}" [isCircle]="true" color="{{brColor}}" fontSize="14px" style="margin-right: 1rem;"></app-so-badge>
          }
          <div>
            <p class="card-title-medium">{{title}}</p>
            <p class="card-description" [class]=" 'description-color-'+ brColor">{{description}}</p>
          </div>
        </div>
        <h6 class="card-datee">{{date}}</h6>
      
        <div class="d-flex justify-content-between mt-2">
          @if (badgeText) {
            <app-so-badge text="{{badgeText | translate}} " color="{{brColor}}" fontSize="12px" fontWeight="bold"></app-so-badge>
          }
          <div class="d-flex ">
            @for (icons of icons; track $index) {
              <mat-icon class=" icon-check-circle mr-3" style="font-size: 24px;">{{icons}}</mat-icon>
            }
           
          </div>
        </div>
        <div>
        
        </div>
        <div>
          <ng-content select=".extraLarge">
          
          </ng-content>
        </div>
      </div>
    </div>

  }
  @case ('large'){
    <!-- LARGE -->
    <div class="card  card-border " [class]="'br-'+ brColor">
      <div class="card-body card-body-padding">
        <div class="d-flex align-items-center">
          @if (icon) {
            <app-so-badge icon="{{icon}}" [isCircle]="true" color="{{brColor}}" fontSize="14px" style="margin-right: 1rem;"></app-so-badge>
          }
          <div>
            <p class="card-title-medium">{{title}}</p>
            <p class="card-description" [class]=" 'description-color-'+ brColor">{{description}}</p>
          </div>
        </div>
        <div class="d-flex justify-content-between mt-2">
          <h6 class="card-datee">{{date}}</h6>
          @if (badgeText) {
            <app-so-badge text="{{badgeText | translate}} " color="{{brColor}}" fontSize="12px" fontWeight="bold"></app-so-badge>
          }
        </div>
        <div>
          <ng-content select=".large">
          
          </ng-content>
        </div>
      </div>
    </div>
  }
  @case ('medium'){
    <!-- MEDIUM -->
    <div class="card  card-border " [class]="'br-'+ brColor" [style]="'background-color: var(' + bgColor + ');'">
      <div class="card-body-medium">
        <p class="card-title-small" [ngClass]="{'text-nowrap overflow-hidden': !allowTextWrap}">{{title}}</p>
        <p class="card-description-medium" [class]=" 'description-color-'+ brColor">{{description}}</p>
        <h6 class="card-datee">{{date}}</h6>
        <div class="d-flex mt-2 justify-content-between ">
          <div class="badge-container">
            @if (icon) {
              <app-so-badge icon="{{icon}}" [isCircle]="true" color="{{brColor}}" fontSize="13px" [smallCircle]="true"></app-so-badge>
            }
            @if (badgeText) {
              <app-so-badge text="{{badgeText | translate}} " color="{{brColor}}" fontSize="13px"></app-so-badge>
            }
            @if (brColor == 'danger-light') {
              <app-so-svg-icon src="assets/img/timelapse-danger.svg" color="--so-danger" width="22" height="22"/>
            }
          </div>
          @if (checkCircle) {
            <app-so-svg-icon src="assets/img/mdi-check-circle-outline.svg" color="--so-secondary" width="22" height="22"/>
          }
        
        </div>
        <div>
          <ng-content select=".medium">
          
          </ng-content>
        </div>
      </div>
    </div>
  }
  @case ('small') {
    <!--  SMALL -->
    <div class="card card-border-{{ sizeCard }}" [ngClass]="lowOpacity ? 'br-'+brColor+'-low-opacity': 'br-'+brColor">
      <div class="card-body-{{ sizeCard }}">
        <div class="d-flex flex-row justify-content-between"  style="width: 100%;">
          <div class="d-flex flex-column" style="width: 70%;">
            <p class="card-title-{{ sizeCard }}" style="padding: 0 5px 0 5px;">{{title}}</p>
            <div class="d-flex flex-row" style="margin-top: -4px; padding: 0;">
              @if (badgeText) {
                <app-so-badge text="{{badgeText | translate}} " color="{{brColor}}" fontSize="11px"></app-so-badge>
              }
              @if(brColor == 'danger-light'){
                <div style="width: 20px; margin-left: 5px;">
                  <app-so-svg-icon src="assets/img/timelapse-danger.svg" color="--so-danger" width="22" height="22"/>
                </div>
              }
            </div>
          </div>
          <div class="d-flex flex-column align-items-end" style="width: 30%;" [ngClass]="date == '' ? 'justify-content-center' : ''">
            <label class="so-text-4" style="margin: -6px 0 0 0; white-space: nowrap;">{{date}}</label>
            @if(checkCircle){
              <mat-icon class="icon-check-circle" style="transform: scale(1.5);">{{ icon || 'check_circle_outline' }}</mat-icon>
            }
          </div>
        </div>
        <p class="br-{{brColor}} so-text-4 mb-0" style="padding: 0 5px 0 5px;">{{text}}</p>
      </div>
    </div>
  }
  @case ('deleteOption'){
    <div class="card h-100 card-border card-shadow" [class]="'br-'+ brColor">
      <div class="card-body card-body-padding">
        <div class="d-flex align-items-center w-100 justify-content-between">
          <div class="d-flex align-items-center w-100">
            @if (icon) {
              <app-so-badge icon="{{icon}}" [isCircle]="true" color="{{brColor}}" style="margin-right: 1rem;" [iconsize]="1.5" [fontSize]="'18px'"></app-so-badge>
            }
            <div class="w-100">
              <p class="card-title-medium" [style]="titleStyle">{{title}}</p>
              <p class="card-description" [style]="descriptionStyle" [class]=" 'description-color-'+ brColor">{{description}}</p>
            </div>
          </div>
          <app-so-svg-icon src="assets/img/mdi-delete-forever-outline-danger.svg" color="--so-danger" width="22" height="22" class="align-self-center" [style.cursor]="'pointer'" (click)="deleteRegister()"/>
        </div>
        <div class="d-flex mt-2">
          <h6 class="card-datee">{{date}}</h6>
          @if (badgeText) {
            <app-so-badge text="{{badgeText | translate}} " color="{{brColor}}" fontSize="12px" fontWeight="bold"></app-so-badge>
          }
        </div>
      </div>
    </div>        
  }
  @case ('deleteEditOptions'){
    <div class="card h-100 card-border card-shadow" [class]="'br-'+ brColor">
      <div class="card-body card-body-padding">
        <div class="d-flex align-items-center w-100 justify-content-between">
          <div class="col-11"> 
            <div class="d-flex align-items-center">
              @if (icon) {
                <app-so-badge icon="{{icon}}" [isCircle]="true" color="{{brColor}}" style="margin-right: 1rem;" [iconsize]="1.5" [fontSize]="'18px'"></app-so-badge>
              }
              <div class="flex-fill" style="min-width: 0;">
                <p class="card-title-medium" [style]="titleStyle" [title]="title">{{title}}</p>
                <p class="card-description" [style]="descriptionStyle" [class]=" 'description-color-'+ brColor">{{description}}</p>
              </div>
            </div>
          </div>
          <div class="col-1">
            <div class="buttons d-flex" style="gap: 12px;">
              <app-button
                type="button"
                svg="mdi-pencil-outline-white"
                [tooltip]="'edit' | translated : 'Editar'"
                [scale]=".9"
                [injection]="{icon: 'margin-top: 0px !important', button: '' }"
                [applyStyles]="false"
                [applyChanges]="true"
                (clicked)="editRegister()"
              />
              <app-button
                type="button"
                svg="mdi-delete-forever-outline-danger"
                colorIcon="--so-danger"
                [tooltip]="'erase' | translated : 'Borrar'"
                [scale]="1"
                [injection]="{icon: 'margin-top: 0px !important', button: '' }"
                [applyStyles]="false"
                [applyChanges]="true"
                (clicked)="deleteRegister()"
              />
            </div>
          </div>
        </div>
        <div class="d-flex mt-2">
          <h6 class="card-datee">{{date}}</h6>
          @if (badgeText) {
            <app-so-badge text="{{badgeText | translate}} " color="{{brColor}}" fontSize="12px" fontWeight="bold"></app-so-badge>
          }
        </div>
      </div>
    </div>        
  }
  @case ('acceptRejectOptions'){
    <div class="card h-100 card-border card-shadow" [class]="'br-'+ brColor">
      <div class="card-body card-body-padding">
        <div class="d-flex align-items-center w-100 justify-content-between">
          <div class="col-10"> 
            <div class="d-flex align-items-center">
              @if (icon) {
                <app-so-badge icon="{{icon}}" [isCircle]="true" color="{{brColor}}" style="margin-right: 1rem;" [iconsize]="1.5" [fontSize]="'18px'"></app-so-badge>
              }
              <div class="flex-fill" style="min-width: 0;">
                <p class="card-title-medium" [style]="titleStyle" [title]="title">{{title}}</p>
                <p class="card-description" [style]="descriptionStyle" [class]=" 'description-color-'+ brColor">{{description}}</p>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="buttons d-flex" style="gap: 12px;">
              <app-button
                type="button"
                svg="mdi-check-circle-outline"
                [tooltip]="'accept' | translated : 'Aceptar'"
                [scale]="1"
                [injection]="{icon: 'margin-top: 0px !important', button: '' }"
                [applyStyles]="false"
                [applyChanges]="true"
                (clicked)="acceptRegister()"
              />
              <app-button
                type="button"
                svg="mdi-pencil-outline-white"
                [tooltip]="'edit' | translated : 'Editar'"
                [scale]=".9"
                [injection]="{icon: 'margin-top: 0px !important', button: '' }"
                [applyStyles]="false"
                [applyChanges]="true"
                (clicked)="editRegister()"
              />
              <app-button
                type="button"
                [svg]="isDeleteOption ? 'mdi-delete-forever-outline-danger' : 'mdi-close-circle-outline-danger'"
                colorIcon="--so-danger"
                [tooltip]="isDeleteOption ? ('erase' | translated : 'Borrar') : ('reject' | translated : 'Rechazar')"
                [scale]="isDeleteOption ? 1 : 1.5"
                [injection]="{icon: 'margin-top: 0px !important', button: '' }"
                [applyStyles]="false"
                [applyChanges]="true"
                (clicked)="isDeleteOption ? deleteRegister() : rejectRegister()"
              />
            </div>
          </div>
        </div>
        <div class="d-flex mt-2">
          <h6 class="card-datee">{{date}}</h6>
          @if (badgeText) {
            <app-so-badge text="{{badgeText | translate}} " color="{{brColor}}" fontSize="12px" fontWeight="bold"></app-so-badge>
          }
        </div>
      </div>
    </div>        
  }
  @default {
    <!--  Auto -->
    <div class="card card-border-{{ sizeCard }} br-{{brColor}}" *ngIf="sizeCard == 'auto'">
      <div class="card-body-{{ sizeCard }}">
        <p class="card-title-{{ sizeCard }}">{{title}}</p>
        <div class=" d-flex justify-content-between mt-2">
          <app-so-badge text="{{badgeText | translate}} " color="{{brColor}}" fontSize="14px"
            *ngIf="badgeText"></app-so-badge>
          <mat-icon class=" icon-check-circle " style="font-size: 26px;" *ngIf="checkCircle">{{ icon ||
            'check_circle_outline' }}</mat-icon>
        </div>
        <div>
          <ng-content select=".small">
          
          </ng-content>
        </div>
      </div>
    </div>
  }
}
