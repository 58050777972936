<span class="{{ styleBadge }} {{class}} badge {{isCircle ? 'badge-circle' : '' }}"
  [style.font-size]=" fontSize + ' !important'"
  [style.font-weight]=" fontWeight + ' !important'"
  [style.width]="isCircle ?  (smallCircle ? '2em' : badgeSize) : width"
  [style.height]="isCircle ? (smallCircle ? '2em' : badgeSize) : height"
>
  {{text}}
  <so-icon icon="{{icon}}" [svg]="svg" [size]="iconsize" *ngIf="icon" [color]="color" class="pt-1"/>
</span>



<!-- <span class="{{ styleBadge }} badge {{class}} " [style.font-size]=" fontSize " [style.font-weight]=" fontWeight "
  [ngStyle]="{'padding': allBorderRadius ? '6px' : '', 'border-radius': allBorderRadius ? '100%' : ''}">
  <div class="d-flex ">
    <mat-icon class=" badge-icon " [class]=" (iconAlignment == 'left' ? ' ' : ' order-last ')  "
      [style.font-size]=" fontSize " *ngIf="icon">{{icon}}</mat-icon>
    <p *ngIf="text != ''">{{text}}</p>
  </div>
</span>
 -->
