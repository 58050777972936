<div class="d-flex">
  @if(checkLabel){
    <div 
      class="d-flex align-items-center ratio__component"
      [ngClass]="labelPosition"
      [style]="'width: fit-content;' + (!isDisabled ? '' : 'opacity: .5;') + 'gap:' + gap + ';' + labelContainerStyle"
      (click)="!isDisabled && onClick.emit()"
    >
    <label 
      [for]="ratioId"
      [style]="'font-weight: ' + fontWeight + '!important;color: '+ labelColor +' ;opacity:'+opacity +'; font-size: '+ fontSize +' ;' + labelStyle">
      {{ text }}
    </label>
    @if(!checked){
      <input 
        class="center-element"
        type="radio" 
        [name]="nameGroup" 
        [id]="ratioId" 
        (click)="validarStatus($event)" 
        [disabled]="isDisabled"
      />
    }@else {
      <mat-icon (click)="validarStatus($event)" class="so-success center-element" [style]="checkedIconStyle">check_circle_outline</mat-icon>
    }
  </div>
  }@else {
    <div 
      class="d-flex align-items-center ratio__component"
      [ngClass]="labelPosition"
      [style]="'width: fit-content;gap:' + gap + ';' + labelContainerStyle"
      (click)="onClick.emit()"
    >
      <label 
        [for]="ratioId"
        [style]="'font-weight: ' + fontWeight + '!important;color: '+ labelColor +' ;opacity:' + opacity + '; font-size: '+ fontSize +' ;' + labelStyle">
        {{ text }}
      </label>
      <input 
        class="custom-radio"
        style="width: 23px!important;height: 23px!important;" 
        [name]="nameGroup" 
        [id]="ratioId" 
        [disabled]="isDisabled"
        (click)="validarStatus($event)" class="center-element" 
      />
    </div>
  }
</div>
