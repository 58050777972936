import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { SortingHelperService } from '@shared/helpers/utils/sorting-helper.service';

@Component({
  selector: 'app-so-table',
  templateUrl: './so-table.component.html',
  styleUrl: './so-table.component.scss'
})
export class SoTableComponent {
  @Input({ required: true }) set tableData(value: Record<string, any>[]) {
    if(value) this._tableData = JSON.parse(JSON.stringify(value));
  }
  @Input({required:true}) tableHeaders: {header:string, dataName:string}[] = [];
  @Input({required:true}) isActionsEnabled: boolean = false;
  @Input({required:true}) enableRowSelection:boolean = false;
  @Input({required:true}) showSwitches: boolean = false;
  @Input({required:true}) enablePagination: boolean = false;
  @Input() deleteAction: boolean = true;
  @Input() editAction: boolean = true;
  @Input() itemsPerPage: number = 15;
  @Input() currentPage: number = 1;
  // se requiere cuando el showSwitches es true
  @Input() switchHeader:string = ''
  // se requiere cuando el showSwitches es true
  @Input() switchIdentifier: string = '';
  @Input() switchLabelLeft:string = 'No';
  @Input() switchLabelRight:string = 'Si';
  @Input() enableSaveButton:boolean = false;
  @Input() enableAllSwitches:boolean = true;
  @Input() headerStyle: 'classic' | 'new' = 'new';
  @Input() searchText: string = '';
  @Output() switchEvent: EventEmitter<{data:Record<string, any>, status:boolean}> = new EventEmitter();
  @Output() rowClickedEvent: EventEmitter<Record<string, any>> = new EventEmitter();
  @Output() saveEvent: EventEmitter<Record<string, any>[]> = new EventEmitter();
  
  private _tableData: Record<string, any>[] = [];
  private originalSet: Record<string, any>[] = [];
  private sortDirection: 'asc' | 'desc' = 'asc';

  public filteredData: Record<string, any>[] = [];
  public selectedRow: Record<string, any> | null = null;
  
  constructor(    
    private sortingHelperService: SortingHelperService,
  ){
  }
  ngOnInit(): void {
    this.originalSet = JSON.parse(JSON.stringify(this.tableData));
    this.tableData =  this.tableData.map((element) => {
      if(this.showSwitches) element = {...element, disabledSwitch: !this.enableAllSwitches}
      return element;
    });
    this.filteredData = this.tableData;
    this.updateDisplayedData();
    this.filteredData =  this.filteredData.map((element) => {
      if(this.showSwitches) element = {...element, disabledSwitch: !this.enableAllSwitches}
      return element;
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    //Cambios en TableData
    if (changes['tableData'] && !changes['tableData'].isFirstChange()) {
      this.handleTableDataChange();
    }
    //Deshabilitar todos los switches
    if(changes['enableAllSwitches']){
      if(this.enablePagination){
        this.filteredData = this.filteredData.map((data) => {
          data['disabledSwitch'] = !changes['enableAllSwitches'].currentValue;
          return data
        })
      }else{
        this.tableData = this.tableData.map((data) => {
          data.disabledSwitch = !changes['enableAllSwitches'].currentValue;
          return data
        })
      }
    }
    if(changes['searchText'] && !changes['searchText'].isFirstChange()){
      this.handleSearch(changes['searchText'].currentValue);
    }
  }
  handleTableDataChange(): void {
    this.originalSet = JSON.parse(JSON.stringify(this.tableData)); // Copia profunda
    this.tableData = this.originalSet.map((element) => {
      if(this.showSwitches){
        element = {...element, disabledSwitch: !this.enableAllSwitches};
      } 
      return element;
    })
    if(this.enablePagination){
      this.filteredData = this.tableData.map((element) => {
        if(this.showSwitches) element = {...element, disabledSwitch: !this.enableAllSwitches};
        return element;
      });
      this.updateDisplayedData();
    }
  }
  handleSearch(value: string): void {
    if (value) {
      if(this.enablePagination){
        this.filteredData = this._tableData.filter((item) => 
          Object.keys(item).some(key => 
            item[key] && item[key].toString().toLowerCase().includes(value.toLowerCase())
          )
        );
      }else{
        this.tableData = this.tableData.filter((item) => 
          Object.keys(item).some(key => 
            item[key] && item[key].toString().toLowerCase().includes(value.toLowerCase())
          )
        );
      }
    } else {
      if(this.enablePagination){
        this.filteredData = [...this.originalSet];
        this.currentPage = 1;
        this.updateDisplayedData();
      }else{
        this.currentPage = 1;
        this.tableData = [...this.originalSet]
      }
    }
    
  }
  save(){
    this.saveEvent.emit();
  }
  onClickShortByData(field: string){
    if(this.enablePagination){
      this.filteredData = this.sortingHelperService.sortArray(this.filteredData, field, this.sortDirection);
    }else{
      this.tableData = this.sortingHelperService.sortArray(this.tableData, field, this.sortDirection);
    }
    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    this.currentPage = 1;
  }
  onPageChanged(newPage: number): void {
    this.currentPage = newPage;
    this.updateDisplayedData();
  }

  updateDisplayedData(): void {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.filteredData = this.tableData.slice(startIndex, endIndex);
  }
  onSwitchActivated(data:Record<string, any>, status: boolean){
    if(data != this.selectedRow) this.rowClickedEvent.emit(data);
    data[this.switchIdentifier] = status
    this.selectedRow = data;
    this.switchEvent.emit({ data, status });
  }
  onRowClic(data:Record<string, any>){
    this.selectedRow = data;
    this.rowClickedEvent.emit(data);
  }
  get tableData(): any[] {
    return this._tableData;
  }
}
