import { Component, EventEmitter, inject, Input, OnChanges, Output } from '@angular/core';
import { TranslateService } from '@core/api/translate.service';
import { translateFn } from '@shared/helpers/closures';

@Component({
  selector: 'so-pagination',
  templateUrl: './so-pagination.component.html',
  styleUrl: './so-pagination.component.scss'
})
export class SoPaginationComponent implements OnChanges {

  @Input() totalItems: number = 0;
  @Input() itemsPerPage: number = 10;
  @Input() currentPage: number = 1;
  @Input() showText: boolean = true;
  @Input() paddingEnd: string = '2rem';
  @Input() loadingTotal: boolean = false;
  @Input() prevDisabled: boolean = false;
  @Input() nextDisabled: boolean = false;
  @Input() lang: string = '';

  @Output() pageChanged = new EventEmitter<number>();

  private readonly translate = translateFn(inject(TranslateService));

  public textShowRecords: string = '';
  public textShowRecordsPart1: string | null = null;
  public textShowRecordsPart2: string | null = null;
  public textShowRecordsPart3: string | null = null;

  ngOnChanges(): void {
    this.setTextShowRecords();
  }

  setTextShowRecords(){
    const lastIndex = this.getCurrentPageLastIndex();
    const firstIndex = lastIndex > 0 ? this.getCurrentPageFirstIndex() : 0;
    const totalRecords = this.totalItems;
    const totalPages = this.getTotalPages();
    const loadingRegEx: RegExp = totalPages <= 1 ? /(.+\D+)(\d+)(\D+)$/ : /(.+\D+)(\d+)(\D+)(\d+)(\D+)$/;

    if (totalPages <= 1) {
      const translatedText  = this.translate('showing_records_of_table', 'Mostrando registros del %s al %s de un total de %s registros.', this.lang);
      this.textShowRecords = this.formatString(translatedText, firstIndex, lastIndex, totalRecords);

      // Mostrar spinner de carga para el total
      if (this.loadingTotal) {
        const matches = this.textShowRecords.match(loadingRegEx);
        if (matches && matches.length === 4) {
          this.textShowRecordsPart1 = matches[1];
          this.textShowRecordsPart2 = matches[3];
          this.textShowRecordsPart3 = null;
        }
        else {
          this.textShowRecordsPart1 = null;
          this.textShowRecordsPart2 = null;
          this.textShowRecordsPart3 = null;
        }
      }
    }
    else {
      const translatedText  = this.translate('showing_records_and_pages_of_table', 'Mostrando registros del %s al %s de un total de %s en %s páginas.', this.lang);
      this.textShowRecords = this.formatString(translatedText, firstIndex, lastIndex, totalRecords, totalPages);

      // Mostrar spinner de carga para el total y la cantidad de páginas
      if (this.loadingTotal) {
        const matches = this.textShowRecords.match(loadingRegEx);
        if (matches && matches.length === 6) {
          this.textShowRecordsPart1 = matches[1];
          this.textShowRecordsPart2 = matches[3];
          this.textShowRecordsPart3 = matches[5];
        }
        else {
          this.textShowRecordsPart1 = null;
          this.textShowRecordsPart2 = null;
          this.textShowRecordsPart3 = null;
        }
      }
    }
  }

  getTotalPages(): number {
    return this.itemsPerPage === 0 ? 0 : Math.ceil(this.totalItems / this.itemsPerPage);
  }

  getCurrentPageFirstIndex(): number {
    return (this.currentPage - 1) * this.itemsPerPage + 1;
  }

  getCurrentPageLastIndex(): number {
    return Math.min(this.currentPage * this.itemsPerPage, this.totalItems);
  }

  goToPage(page: number): void {
    if (page >= 1 && page <= this.getTotalPages()) {
      this.currentPage = page;
      this.pageChanged.emit(this.currentPage);
      this.setTextShowRecords();
    }
  }

  goToPreviousPage(): void {
    if (this.currentPage > 1) {
      this.goToPage(this.currentPage - 1);
      this.setTextShowRecords();
    }
  }

  goToNextPage(): void {
    if (this.currentPage < this.getTotalPages()) {
      this.goToPage(this.currentPage + 1);
      this.setTextShowRecords();
    }
  }

  getPaginationArray(): number[] {
    return Array(this.getTotalPages()).fill(0).map((x, i) => i + 1);
  }

  private formatString(text: string, ...args: any[]): string {
    return text.replace(/%s/g, () => args.shift());
  }

}
