<div class="range-slider-container p-1">
    <div class="range-header">
      <div class="d-flex justify-content-start align-items-center">
        <span class="range-label">{{ label }}</span>
        @if(showIcon){
            <so-icon
            svg="mdi-information"
            [applyChange]="true"
            [size]=".8"
            [tooltip]="tooltip"
            ></so-icon>
        }
      </div>
      @if(isInteger){
        <span class="range-value">{{ (value) + ' ' +(labelValue) }}</span>
      }@else{
        <span class="range-value">{{ (value | number: '1.2-2') + ' ' +(labelValue) }}</span>
      }
    </div>
    <input
      type="range"
      class="range-slider"
      [value]="value * converNumberValue"
      [disabled]="isDisabled"
      [min]="minValue * converNumberValue"
      [max]="maxValue * converNumberValue"
      [ngStyle]="{
       '--progress': ((value * converNumberValue) - (minValue * converNumberValue)) / ((maxValue * converNumberValue) - (minValue * converNumberValue)) * 100 + '%'
      }"
      (input)="updateValue($event)"
    />
</div>
  